<template>
  <v-card>
    <div :class="`white--text align-end ${profile.img ? 'logo-container' : ''} primary ${$vuetify.breakpoint.xs?'container-xs':''} ${$vuetify.breakpoint.sm?'container-sm':''}`">
      <span v-if="profile.img" :class="`event-logo pa-3 v-card elevation-7 v-sheet theme--light`">
        <img :alt="`Profile picture of ${profile.name}`" :src="profile.img" />
      </span>
      <v-card-title class="display-2" v-text="profile.name"></v-card-title>
    </div>

    <div v-if="profile" class="pa-4">
      <h1>{{ $t('profile.friends.request-as-friend') }}</h1>
      <p>{{ $t('profile.friends.request-msg', { user: profile.name }) }}</p>

      <v-btn large color="primary" @click="requestFriend">{{ $t('profile.friends.request-as-friend') }}</v-btn>
    </div>
  </v-card>
</template>



<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import ShareButton from '@/components/ShareButton.vue';
import Header from '../_Header.vue';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Friends",
  components: {
    Header,
    ShareButton,
  },
  props: {
  },
  data() {
    return {
      profile: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getProfile() {
      if (this.user) {
        if (!this.profile) {
          var response = await profileService.getProfileSummary(this.$route.params.profileId);
          this.profile = response.data;
        }
      }
    },
    async requestFriend() {
      const response = (await profileService.requestFriend(this.$route.params.profileId)).data;
      this.$helpers.toastResponse(this, response, this.$t('profile.friends.request-confirmation'));
      this.$router.push({name: 'profileFriends'});
    },

  },
  computed: {

    ...mapGetters({
      user: "user"
    }),

  },

};
</script>



<style lang="scss">
  .logo-container { overflow: inherit !important; margin-bottom: 42px; position: relative; min-height: 200px; display: flex;}
  .logo-container .v-responsive__sizer { padding-bottom: 0!important;}
  .logo-container .display-2 { display: inline-block; margin-left: 250px;}
  .logo-container.container-xs .display-2 { margin-left: 150px;}
  .logo-container.container-sm .display-2 { margin-left: 200px;}
  .event-logo { background-color: white; display: inline-block !important; left: 16px; position: absolute !important; bottom: -32px; }
  .event-logo img { max-width: 200px; }
  .container-xs .event-logo img { max-width: 100px; }
  .container-sm .event-logo img { max-width: 150px; }
</style>